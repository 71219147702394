import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import KishuCounter from './KishuCounter.js';

function App() {
  return KishuCounter();
}

export default App;
